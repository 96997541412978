import { useAccount, useMsal } from "@azure/msal-react";
import {
  Box,
  Container,
  Flex,
  HStack,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { ReactGrid } from "@silevis/reactgrid";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../../../hooks/useFetchWithMsal";
import SaveButton from "../../../Save";
import { CustomSpinner } from "../../../Spinner";
import {
  FormatDateToMonthYear1,
  getCellClassName,
} from "../RegionalCanvasHelper";
import RegionalLeaderTable from "../RegionalLeaderTable";
// import TextWrapRenderer from './TextWrapRenderer';
// import * as FileSaver from "file-saver";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { BsCalendar2X, BsCardList } from "react-icons/bs";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineCancel, MdOutlineTableRows } from "react-icons/md";
import { findMaxId } from "../../../../Constants";
import ChangeLog from "../../../ChangeLog/ChangeLog";
import CustomAlertDialogBox from "../../../CustomAlertDialogBox/CustomAlertDialogBox";
import "./../style.scss";

export default function CustomerWarehouseSplitContent({
  setDataSaved,
  ActiveEnv,
  CustomerMasterRows1,
  CustomerRowMapping1,
  SupplySkuNonEditableRows,
  SupplySkuColumnsDatabase,
  DropDownRows,
  DropDownMasters,
  DropDownMasterColumnsMapping,
  dependenciesMapping,
  retrieveURL,
  updateURL,
  deleteURL,
}) {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { pathname } = useLocation();
  const [int1, setInt] = useState(0);
  // const [{ data, loading, error}, fetchData] = useFetch();
  const prettySheets = JSON.parse(sessionStorage.getItem("prettySheets"));
  const [id_sheet] = useState(pathname ? parseInt(pathname.split("-")[1]) : 0);
  const navigate = useNavigate();
  const [dataGrouped, setDataGrouped] = useState({});
  const [dataGroupedOriginal, setDataGroupedOriginal] = useState({});
  const [DropDownMaster, setDropDownMaster] = useState({});
  const arrayofkeys = Object.keys(dataGrouped);
  // const maxIDinEffect = dataGrouped[arrayofkeys[arrayofkeys.length-1]]["SplitId"]
  const [maxID, setMaxID] = useState(
    arrayofkeys.length === 0
      ? 0
      : dataGrouped[arrayofkeys[arrayofkeys.length - 1]]["SplitId"]
  );
  const [maxIDInDB, setMaxIDInDB] = useState(
    arrayofkeys.length === 0
      ? 0
      : dataGrouped[arrayofkeys[arrayofkeys.length - 1]]["SplitId"]
  );
  const [{ loading, error }, fetchData] = useFetch();
  const [, updateData] = useFetch();
  const [, deleteData] = useFetch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [changesTracker, setChangesTracker] = useState({});
  const toast = useToast();
  const toastIdRef = useRef();
  const [Dates, setDates] = useState([]);
  const [DatesOriginal, setDatesOriginal] = useState([]);
  const [toAddDate, SetToAddDate] = useState(null);
  const [deleteCheckBox, setDeleteCheckBox] = useState(false);
  const [deleteCheckBoxColumns, setDeleteCheckBoxColumns] = useState(false);
  const [deleteTracker, setDeleteTracker] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPrevious, setShowPrevious] = useState(false);

  // Alert box state
  const [isAlertBoxOpen, setIsAlertBoxOpen] = useState(false);
  const [comment, setComment] = useState("");

  const getOptionType = useCallback(
    (DropDownMaster) => {
      if (Object.keys(DropDownMaster).length === 0) return {};
      var masterMappingDict = {};
      DropDownMasters.forEach((masterMappings) => {
        masterMappingDict[masterMappings] = Object.entries(
          DropDownMaster[masterMappings]
        ).map(([key, value]) => {
          return { label: `${key}`, value: `${key}` };
        });
      });
      return masterMappingDict;
    },
    [DropDownMasters]
  );

  const [optionType, setOptionType] = useState({});

  const handleChanges = (changes) => {
    const CustomerRowMapping = CustomerRowMapping1;
    Dates.forEach((date_value) => {
      CustomerRowMapping[date_value] = date_value;
    });
    setInt(int1 + 1);
    if (
      changes[0].type === "dropdown" &&
      changes[0].newCell.text === changes[0].previousCell.text
    ) {
      setRows((rowsTemp) => {
        let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId);
        dataRow["cells"][
          columns.findIndex(
            (columnId) => columnId["columnId"] === changes[0]["columnId"]
          )
        ]["isOpen"] = changes[0].newCell.isOpen;
        return [...rowsTemp];
      });
    } else if (
      changes[0].type === "checkbox" &&
      changes[0].newCell.checked !== changes[0].previousCell.checked
    ) {
      setRows((rowsTemp) => {
        let dataRow = rows.find((trial1) => trial1.rowId === changes[0].rowId);
        dataRow["cells"][
          columns.findIndex(
            (columnId) => columnId["columnId"] === changes[0]["columnId"]
          )
        ]["checked"] = changes[0].newCell.checked;
        return [...rowsTemp];
      });
      if (Dates.findIndex((value) => value === changes[0].columnId) !== -1) {
        setDeleteTracker((prevData) => {
          var updatedData = prevData;
          var columnstodelete = updatedData["columnstodelete"]
            ? updatedData["columnstodelete"]
            : [];
          if (changes[0].newCell.checked) {
            if (
              columnstodelete.findIndex(
                (value) => value === changes[0].columnId
              ) === -1
            ) {
              columnstodelete.push(changes[0].columnId);
            }
          } else {
            const indexToRemove = columnstodelete.indexOf(changes[0].columnId);
            if (indexToRemove !== -1) {
              columnstodelete.splice(indexToRemove, 1);
            }
          }
          updatedData["columnstodelete"] = columnstodelete;
          return updatedData;
        });
      } else {
        setDeleteTracker((prevData) => {
          var updatedData = prevData;
          var rowstodelete = updatedData["rowstodelete"]
            ? updatedData["rowstodelete"]
            : [];
          if (changes[0].newCell.checked) {
            if (rowstodelete.indexOf(changes[0].rowId) === -1) {
              rowstodelete.push(changes[0].rowId);
            }
          } else {
            const indextoremove = rowstodelete.indexOf(changes[0].rowId);
            if (indextoremove !== -1) {
              rowstodelete.splice(indextoremove, 1);
            }
          }
          updatedData["rowstodelete"] = rowstodelete;
          return updatedData;
        });
      }
    } else {
      setIsButtonDisabled(false);
      setDataSaved(false);
      if (
        (changes[0].newCell.text === "" || changes[0].newCell.text === null) &&
        Dates.findIndex((value) => value === changes[0].columnId) === -1
      ) {
        const toastSettings = {
          description: changes[0].columnId + " cannot be left blank",
          status: "error",
          isClosable: true,
        };
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, toastSettings);
        } else {
          toast(toastSettings);
        }
      } else {
        var dataTemp = dataGrouped;
        changes.forEach((change) => {
          var [rowId, isNew] = change.rowId.split("-");
          var [changesplitId, changeId] = rowId.split(" ");
          changesplitId = parseInt(
            changesplitId.replace("(", "").replace(" ", "")
          );
          changeId = parseInt(changeId.replace(")", "").replace(" ", ""));
          const split_type = changesplitId > maxIDInDB ? "new" : "old";
          if (changesplitId <= maxID) {
            if (
              change.columnId === "Customer Name" ||
              change.columnId === "SOP Category"
            ) {
              Object.entries(dataTemp)
                .filter(
                  ([entrykey, entryvalue]) =>
                    entryvalue["SplitId"] === changesplitId
                )
                .forEach(([entrykey, entryvalue]) => {
                  if (
                    DropDownRows.findIndex(
                      (value) => value === change.columnId
                    ) !== -1
                  ) {
                    let dictionaryToSet = entryvalue;
                    dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                      let mainDict = DropDownMaster[
                        DropDownMasterColumnsMapping[
                          CustomerRowMapping[change.columnId]
                        ]
                      ]
                      dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                        ? mainDict[change.newCell.text][keyofmapping]
                        : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                      }
                    );
                    dataTemp[entrykey] = dictionaryToSet;
                  }
                });
              setChangesTracker((prevchangesTracker) => {
                const updatedChanges = { ...prevchangesTracker };
                const changeType = "CustomerNameSOPCategoryChange";
                const splitId = changesplitId;
                let dictionaryToSend = updatedChanges[
                  `${ActiveEnv}-${splitId}-${split_type}`
                ]
                  ? updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`]
                  : {};
                dictionaryToSend[CustomerRowMapping[change.columnId]] =
                  change.newCell.text;
                dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                  let mainDict = DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ]
                  dictionaryToSend[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                    ? mainDict[change.newCell.text][keyofmapping]
                    : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                });
                dictionaryToSend["changeType"] = dictionaryToSend["changeType"]
                  ? dictionaryToSend["changeType"].includes(changeType)
                    ? dictionaryToSend["changeType"]
                    : dictionaryToSend["changeType"] + `,${changeType}`
                  : `${changeType}`;
                updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`] =
                  dictionaryToSend;
                updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`][
                  "old_dictionary"
                ] = updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`][
                  "old_dictionary"
                ]
                  ? updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`][
                      "old_dictionary"
                    ]
                  : {};
                dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                  let array1 = Object.values(dataGroupedOriginal).filter(
                    (entryvalue) => entryvalue["SplitId"] === changesplitId
                  );
                  if (array1.length > 0) {
                    updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`][
                      "old_dictionary"
                    ][keyofmapping] = array1[0][keyofmapping] ?? "";
                  }
                });
                return updatedChanges;
              });
            } else if (
              change.columnId === "Warehouse Name" &&
              isNew === "new"
            ) {
              let dictionaryToSet = {};
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.text;
              if (
                DropDownRows.findIndex((value) => value === change.columnId) !==
                -1
              ) {
                dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                  let mainDict = DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ]
                  dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                    ? mainDict[change.newCell.text][keyofmapping]
                    : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                });
              }
              let temp2 = Object.entries(dataTemp).filter(
                ([entrykey, entryvalue]) =>
                  entryvalue["SplitId"] === changesplitId
              );
              let temp = temp2[temp2.length - 1][1];
              dictionaryToSet["customer_name"] = temp["customer_name"];
              dictionaryToSet["customer_code"] = temp["customer_code"];
              dictionaryToSet["SOP_Category"] = temp["SOP_Category"];
              Dates.forEach((date252) => {
                dictionaryToSet[date252] = parseInt(null);
              });
              dictionaryToSet["SplitId"] = temp["SplitId"];
              dictionaryToSet["id"] = temp["id"] + 1;
              dataTemp[`(${changesplitId}, ${temp["id"]})`] = dictionaryToSet;
              setChangesTracker((prevchangesTracker) => {
                const updatedChanges = { ...prevchangesTracker };
                const changeType = "WarehouseNameAdd";
                const splitId = changesplitId;
                // const split_type = changesplitId>maxIDInDB?"old":"new"
                let dictionaryToSend = updatedChanges[
                  `${ActiveEnv}-${splitId}-${split_type}`
                ]
                  ? updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`]
                  : {};
                dictionaryToSend["changeType"] = dictionaryToSend["changeType"]
                  ? dictionaryToSend["changeType"].includes(changeType)
                    ? dictionaryToSend["changeType"]
                    : dictionaryToSend["changeType"] + `,${changeType}`
                  : `${changeType}`;
                dictionaryToSend["warehouse_code_added"] = dictionaryToSend[
                  "warehouse_code_added"
                ]
                  ? dictionaryToSend["warehouse_code_added"]
                  : {};
                dictionaryToSend["warehouse_code_added"][
                  `(${changesplitId}, ${temp["id"]})`
                ] =
                  DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ][change.newCell.text]["warehouse_code"];
                SupplySkuColumnsDatabase.forEach((value25) => {
                  dictionaryToSend[value25] = dictionaryToSend[value25]
                    ? dictionaryToSend[value25]
                    : dictionaryToSet[value25];
                });
                updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`] =
                  dictionaryToSend;
                return updatedChanges;
              });
            } else if (change.columnId === "Warehouse Name" && !isNew) {
              let dictionaryToSet = dataTemp[change.rowId];
              dictionaryToSet[CustomerRowMapping[change.columnId]] =
                change.newCell.text;
              if (
                DropDownRows.findIndex((value) => value === change.columnId) !==
                -1
              ) {
                dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                  let mainDict = DropDownMaster[
                    DropDownMasterColumnsMapping[
                      CustomerRowMapping[change.columnId]
                    ]
                  ]
                  dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                    ? mainDict[change.newCell.text][keyofmapping]
                    : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
                });
              }
              dataTemp[change.rowId] = dictionaryToSet;
              setChangesTracker((prevchangesTracker) => {
                const updatedChanges = { ...prevchangesTracker };
                const changeType = dataGroupedOriginal[change.rowId]
                  ? "WarehouseNameChange"
                  : "WarehouseNameAdd";
                const splitId = changesplitId;
                let dictionaryToSend = updatedChanges[
                  `${ActiveEnv}-${splitId}-${split_type}`
                ]
                  ? updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`]
                  : {};
                dictionaryToSend["changeType"] = dictionaryToSend["changeType"]
                  ? dictionaryToSend["changeType"].includes(changeType)
                    ? dictionaryToSend["changeType"]
                    : dictionaryToSend["changeType"] + `,${changeType}`
                  : `${changeType}`;
                if (changeType === "WarehouseNameChange") {
                  dictionaryToSend["warehouse_code_changed"] = dictionaryToSend[
                    "warehouse_code_changed"
                  ]
                    ? dictionaryToSend["warehouse_code_changed"]
                    : {};

                  let DropDownMasterMainDict = DropDownMaster[DropDownMasterColumnsMapping[CustomerRowMapping[change.columnId]]][change.newCell.text]
                  dictionaryToSend["warehouse_code_changed"][`${dataGroupedOriginal[change.rowId]["warehouse_code"]}`
                  ] = DropDownMasterMainDict?DropDownMasterMainDict["warehouse_code"]?DropDownMasterMainDict["warehouse_code"]:"":dataGroupedOriginal[change.rowId]['warehouse_code']?dataGroupedOriginal[change.rowId]['warehouse_code']:"";
                  SupplySkuColumnsDatabase.forEach((value25) => {
                    dictionaryToSend[value25] = dictionaryToSend[value25]
                      ? dictionaryToSend[value25]
                      : dictionaryToSet[value25];
                  });
                  updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`] =
                    dictionaryToSend;
                  return updatedChanges;
                } else {
                  dictionaryToSend["warehouse_code_added"] = dictionaryToSend[
                    "warehouse_code_added"
                  ]
                    ? dictionaryToSend["warehouse_code_added"]
                    : {};
                  dictionaryToSend["warehouse_code_added"][
                    `(${changesplitId}, ${changeId})`
                  ] =
                    DropDownMaster[
                      DropDownMasterColumnsMapping[
                        CustomerRowMapping[change.columnId]
                      ]
                    ][change.newCell.text]["warehouse_code"];
                  updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`] =
                    dictionaryToSend;
                  return updatedChanges;
                }
              });
            } else if (
              Dates.findIndex((value) => value === change.columnId) !== -1
            ) {
              if (
                change.newCell.value < 0 ||
                change.newCell.value > 1 ||
                isNew
              ) {
                const toastSettings = {
                  description: isNew
                    ? "Please Select Warehouse"
                    : "Value entered should be between 0 and 1",
                  status: "error",
                  isClosable: true,
                };
                if (toastIdRef.current) {
                  toast.update(toastIdRef.current, toastSettings);
                } else {
                  toast(toastSettings);
                }
              } else {
                let dictionaryToSet = dataTemp[change.rowId];
                dictionaryToSet[CustomerRowMapping[change.columnId]] =
                  parseFloat(change.newCell.value);
                dataTemp[change.rowId] = dictionaryToSet;
                setChangesTracker((prevchangesTracker) => {
                  const updatedChanges = { ...prevchangesTracker };
                  const changeType = "DateProportionAdded";
                  const changeType2 = dataGroupedOriginal[change.rowId]
                    ? "WarehouseNameChange"
                    : "WarehouseNameAdd";
                  const splitId = changesplitId;
                  let dictionaryToSend = updatedChanges[
                    `${ActiveEnv}-${splitId}-${split_type}`
                  ]
                    ? updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`]
                    : {};
                  let keyofstoring = dataGroupedOriginal[`${change.rowId}`]
                    ? dataGroupedOriginal[`${change.rowId}`]["warehouse_code"]
                    : `${change.rowId}`;
                  if (changeType2 === "WarehouseNameChange") {
                    dictionaryToSend["warehouse_code_changed"] =
                      dictionaryToSend["warehouse_code_changed"]
                        ? dictionaryToSend["warehouse_code_changed"]
                        : {};
                    dictionaryToSend["warehouse_code_changed"][
                      `${dataGroupedOriginal[change.rowId]["warehouse_code"]}`
                    ] = dataGrouped[change.rowId]["warehouse_code"];
                  }
                  SupplySkuColumnsDatabase.forEach((value25) => {
                    dictionaryToSend[value25] = dictionaryToSend[value25]
                      ? dictionaryToSend[value25]
                      : dictionaryToSet[value25];
                  });
                  dictionaryToSend["changeType"] = dictionaryToSend[
                    "changeType"
                  ]
                    ? dictionaryToSend["changeType"].includes(changeType)
                      ? dictionaryToSend["changeType"]
                      : dictionaryToSend["changeType"] + `,${changeType}`
                    : `${changeType}`;
                  dictionaryToSend[keyofstoring] = dictionaryToSend[
                    keyofstoring
                  ]
                    ? dictionaryToSend[keyofstoring]
                    : {};
                  dictionaryToSend[keyofstoring][change.columnId] = parseFloat(
                    change.newCell.value
                  );
                  dictionaryToSend["DatesModified"] = dictionaryToSend[
                    "DatesModified"
                  ]
                    ? dictionaryToSend["DatesModified"]
                    : new Set();
                  dictionaryToSend["DatesModified"].add(change.columnId);
                  dictionaryToSend["old_dictionary"] =
                    dictionaryToSend["old_dictionary"] ?? {};
                  dictionaryToSend["old_dictionary"][keyofstoring] =
                    dictionaryToSend["old_dictionary"][keyofstoring] ?? {};
                  dictionaryToSend["old_dictionary"][keyofstoring][
                    change.columnId
                  ] = dataGroupedOriginal[change.rowId]
                    ? dataGroupedOriginal[change.rowId][change.columnId]
                    : null;
                  updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`] =
                    dictionaryToSend;
                  return updatedChanges;
                });
              }
            }
          } else {
            let dictionaryToSet = {};
            dictionaryToSet[CustomerRowMapping[change.columnId]] =
              change.newCell.text;
            CustomerMasterRows1.filter(
              (value) => !(value === change.columnId || value === "SplitId")
            ).forEach((value) => {
              dictionaryToSet[CustomerRowMapping[value]] = "";
            });
            if (
              DropDownRows.findIndex((value) => value === change.columnId) !==
              -1
            ) {
              dependenciesMapping[change.columnId].forEach((keyofmapping) => {
                let mainDict = DropDownMaster[
                  DropDownMasterColumnsMapping[
                    CustomerRowMapping[change.columnId]
                  ]
                ]
                dictionaryToSet[keyofmapping] = mainDict[change.newCell.text]?mainDict[change.newCell.text][keyofmapping]
                  ? mainDict[change.newCell.text][keyofmapping]
                  : "" :dataGroupedOriginal[change.rowId][keyofmapping]?dataGroupedOriginal[change.rowId][keyofmapping]:"";
              });
            }
            Dates.forEach((date252) => {
              dictionaryToSet[date252] = parseInt(null);
            });
            dictionaryToSet["SplitId"] = changesplitId;
            dictionaryToSet["id"] = changeId;
            dataTemp[`(${changesplitId}, ${changeId - 1})`] = dictionaryToSet;
            setChangesTracker((prevchangesTracker) => {
              const updatedChanges = { ...prevchangesTracker };
              const changeType = "NewSplitAdded";
              const splitId = changesplitId;
              let dictionaryToSend = updatedChanges[
                `${ActiveEnv}-${splitId}-${split_type}`
              ]
                ? updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`]
                : {};
              dictionaryToSend["changeType"] = dictionaryToSend["changeType"]
                ? dictionaryToSend["changeType"].includes(changeType)
                  ? dictionaryToSend["changeType"]
                  : dictionaryToSend["changeType"] + `,${changeType}`
                : `${changeType}`;
              SupplySkuColumnsDatabase.forEach((value) => {
                dictionaryToSend[value] = dictionaryToSet[value];
              });
              updatedChanges[`${ActiveEnv}-${splitId}-${split_type}`] =
                dictionaryToSend;
              return updatedChanges;
            });
          }
        });
        setDataGrouped(dataTemp);
        setRows(
          getRows(
            dataTemp,
            dataGroupedOriginal,
            optionType,
            Dates,
            deleteCheckBox,
            deleteCheckBoxColumns,
            showPrevious
          )
        );
        const arrayofkeys = Object.keys(dataTemp);
        const maxIDinEffect =
          dataTemp[arrayofkeys[arrayofkeys.length - 1]]["SplitId"];
        setMaxID(maxIDinEffect);
      }
    }
  };

  const getColumns = useCallback(
    (data, dates1, deleteCheckBox1, showPrevious) => {
      var dates = dates1.reduce((result, ntr) => {
        if (new Date(ntr) >= new Date()) {
          result.push(ntr);
        } else if (showPrevious) {
          result.push(ntr);
        }
        return result;
      }, []);
      var CustomerMasterRows = [...CustomerMasterRows1, ...dates];
      if (deleteCheckBox1) {
        CustomerMasterRows = ["Delete", ...CustomerMasterRows];
      }
      if (Object.keys(data).length === 0) return [];
      return [
        ...CustomerMasterRows.map((column) => {
          return {
            columnId: column,
            width: Math.max(
              ...[
                column.length * 10,
                ...Object.values(data).map((d) =>
                  dates.findIndex((value) => value === column) === -1
                    ? `${d[CustomerRowMapping1[column]]}`.length * 15
                    : `${d[column]}`.length * 10
                ),
              ]
            ),
            resizable: true,
          };
        }),
      ];
    },
    [CustomerMasterRows1, CustomerRowMapping1]
  );

  const getRows = useCallback(
    (
      pivotedData,
      dataGroupedOriginal,
      optionType,
      dates1,
      deleteCheckBox1,
      deleteCheckBoxColumns1,
      showPrevious
    ) => {
      var dates = dates1.reduce((result, ntr) => {
        if (new Date(ntr) >= new Date()) {
          result.push(ntr);
        } else if (showPrevious) {
          result.push(ntr);
        }
        return result;
      }, []);
      var CustomerMasterRows = [...CustomerMasterRows1, ...dates];
      if (deleteCheckBox1) {
        CustomerMasterRows = ["Delete", ...CustomerMasterRows];
      }
      const CustomerRowMapping = CustomerRowMapping1;
      dates.forEach((date_value) => {
        CustomerRowMapping[date_value] = date_value;
      });
      var maxID1 = 0;
      var splitId = 1;
      var key = Object.entries(pivotedData).sort(
        ([par1, var1], [par2, var2]) => {
          return var1["SplitId"] - var2["SplitId"];
        }
      )[0][0];
      var customerName = pivotedData[key]["customer_name"];
      var SOP_Category = pivotedData[key]["SOP_Category"];

      if (
        Object.keys(pivotedData).length === 0 ||
        Object.keys(optionType).length === 0
      )
        return [];
      const headerRowCells = CustomerMasterRows.map((column) => {
        return { type: "header", text: `${column}`, className: "header-cell " };
      });
      var rows = [
        {
          rowId: "header",
          cells: [...headerRowCells],
        },
      ];
      var dictionaryRow = [...rows];
      if (deleteCheckBoxColumns1) {
        var headerRowCells1 = CustomerMasterRows1.map((column) => {
          return { type: "header", text: "", className: "header-cell " };
        });
        var datesdeleteCells = dates.map((column) => {
          if (
            column ===
            dates.reduce((max, arr) => {
              if (new Date(max) < new Date(arr)) {
                max = arr;
              }
              return max;
            }, dates[0])
          ) {
            return {
              type: "checkbox",
              checked: false,
              className: "header-cell ",
              nonEditable: true,
            };
          } else {
            return {
              type: "checkbox",
              checked: false,
              className: "header-cell ",
            };
          }
        });
        var combine = [...headerRowCells1, ...datesdeleteCells];
        if (deleteCheckBox1) {
          combine = [
            { type: "header", text: "", className: "header-cell " },
            ...combine,
          ];
        }
        var varDeleteRows = [
          {
            rowId: "delete-header",
            cells: [...combine],
          },
        ];
        dictionaryRow = [...varDeleteRows, ...rows];
      }
      var count = 1;
      var counter = 1;
      Object.entries(pivotedData)
        .sort(([par1, var1], [par2, var2]) => {
          return var1["SplitId"] - var2["SplitId"];
        })
        .forEach(([parentRowName, value1]) => {
          const newsplitId = parseInt(value1["SplitId"]);
          maxID1 = parseInt(value1["id"]);
          if (newsplitId > splitId) {
            dictionaryRow.push({
              rowId: `(${splitId}, ${count})-new`,
              cells: [
                ...CustomerMasterRows.map((value) => {
                  if (
                    DropDownRows.findIndex((value1) => value1 === value) !== -1
                  ) {
                    if (value === "Customer Name") {
                      return {
                        type: "text",
                        text: `${customerName}`,
                        style: { paddingLeft: 10 },
                        nonEditable: true,
                        className:
                          "editable-cell" +
                          (counter % 2 === 0 ? "-dark " : "-light "),
                      };
                    } else if (value === "SOP Category") {
                      return {
                        type: "text",
                        text: `${SOP_Category}`,
                        style: { paddingLeft: 10 },
                        nonEditable: true,
                        className:
                          "editable-cell" +
                          (counter % 2 === 0 ? "-dark " : "-light "),
                      };
                    }
                    return {
                      type: "dropdown",
                      isOpen: false,
                      values:
                        optionType[
                          DropDownMasterColumnsMapping[
                            CustomerRowMapping[value]
                          ]
                        ],
                      className:
                        "editable-cell" +
                        (counter % 2 === 0 ? "-dark " : "-light "),
                    };
                  } else if (value === "Split Id") {
                    return {
                      type: "text",
                      text: `${splitId}`,
                      className:
                        getCellClassName(SupplySkuNonEditableRows, value) +
                        (counter % 2 === 0 ? "-dark " : "-light "),
                      nonEditable:
                        SupplySkuNonEditableRows.findIndex(
                          (value1) => value1 === value
                        ) !== -1,
                    };
                  } else if (
                    dates.findIndex((value1) => value1 === value) !== -1
                  ) {
                    return {
                      type: "number",
                      value: null,
                      className:
                        getCellClassName(SupplySkuNonEditableRows, value) +
                        (counter % 2 === 0 ? "-dark " : "-light "),
                      nonEditable:
                        SupplySkuNonEditableRows.findIndex(
                          (value1) => value1 === value
                        ) !== -1,
                    };
                  } else if (value === "Delete") {
                    return {
                      type: "text",
                      className: "pale-red-bg ",
                      text: "",
                    };
                  } else {
                    return {
                      type: "text",
                      text: "",
                      className:
                        getCellClassName(SupplySkuNonEditableRows, value) +
                        (counter % 2 === 0 ? "-dark " : "-light "),
                      nonEditable:
                        SupplySkuNonEditableRows.findIndex(
                          (value1) => value1 === value
                        ) !== -1,
                    };
                  }
                }),
              ],
            });
            splitId = newsplitId;
            customerName = value1["customer_name"];
            SOP_Category = value1["SOP_Category"];
            count = 1;
            counter = counter + 1;
          }
          let oneSingleRow = {
            rowId: `${parentRowName}`,
            cells: [
              ...CustomerMasterRows.map((value) => {
                if (
                  DropDownRows.findIndex((value1) => value1 === value) !== -1
                ) {
                  if (
                    (value === "Customer Name" || value === "SOP Category") &&
                    count === 1
                  ) {
                    let dropdownOption = optionType[
                      DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                    ]
                    return {
                      type: "dropdown",
                      selectedValue: `${value1[CustomerRowMapping[value]]}`,
                      isOpen: false,
                      values:dataGroupedOriginal[parentRowName]?
                      dropdownOption.findIndex((value4)=>value4['label']===dataGroupedOriginal[parentRowName][CustomerRowMapping[value]])!==-1?dropdownOption:[{label:`${dataGroupedOriginal[parentRowName][CustomerRowMapping[value]]}`,value:`${dataGroupedOriginal[parentRowName][CustomerRowMapping[value]]}`},...dropdownOption]:dropdownOption,
                      className: "editable-cell" +
                      (counter % 2 === 0 ? "-dark " : "-light "),
                    };
                  } else if (
                    value === "Customer Name" ||
                    value === "SOP Category"
                  ) {
                    return {
                      type: "text",
                      style: { paddingLeft: 10 },
                      text: `${value1[CustomerRowMapping[value]]}`,
                      nonEditable: true,
                      className:
                        "editable-cell" +
                        (counter % 2 === 0 ? "-dark " : "-light "),
                    };
                  } else {
                      let dropdownOption = optionType[
                        DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                      ]
                      return {
                        type: "dropdown",
                        selectedValue: `${value1[CustomerRowMapping[value]]}`,
                        isOpen: false,
                        values:dataGroupedOriginal[parentRowName]?
                        dropdownOption.findIndex((value4)=>value4['label']===dataGroupedOriginal[parentRowName][CustomerRowMapping[value]])!==-1?dropdownOption:[{label:`${dataGroupedOriginal[parentRowName][CustomerRowMapping[value]]}`,value:`${dataGroupedOriginal[parentRowName][CustomerRowMapping[value]]}`},...dropdownOption]:dropdownOption,
                        className: "editable-cell" +
                        (counter % 2 === 0 ? "-dark " : "-light "),
                      };
                  }
                } else if (
                  dates.findIndex((value1) => value1 === value) !== -1
                ) {
                  return {
                    type: "number",
                    value: isNaN(value1[CustomerRowMapping[value]])
                      ? null
                      : parseFloat(value1[CustomerRowMapping[value]]),
                    className:
                      getCellClassName(SupplySkuNonEditableRows, value) +
                      (counter % 2 === 0 ? "-dark " : "-light "),
                    nonEditable:
                      SupplySkuNonEditableRows.findIndex(
                        (value1) => value1 === value
                      ) !== -1,
                  };
                } else if (value === "Delete") {
                  return {
                    type: "checkbox",
                    className: "pale-red-bg ",
                    checked: false,
                  };
                } else {
                  return {
                    type: "text",
                    text: `${value1[CustomerRowMapping[value]]}`,
                    className:
                      getCellClassName(SupplySkuNonEditableRows, value) +
                      (counter % 2 === 0 ? "-dark " : "-light "),
                    nonEditable:
                      SupplySkuNonEditableRows.findIndex(
                        (value1) => value1 === value
                      ) !== -1,
                  };
                }
              }),
            ],
          };
          dictionaryRow.push(oneSingleRow);
          count = count + 1;
        });

      dictionaryRow.push({
        rowId: `(${splitId}, ${maxID1})-new`,
        cells: [
          ...CustomerMasterRows.map((value) => {
            if (DropDownRows.findIndex((value1) => value1 === value) !== -1) {
              if (value === "Customer Name") {
                return {
                  type: "text",
                  style: { paddingLeft: 10 },
                  text: `${customerName}`,
                  nonEditable: true,
                  className:
                    "editable-cell" +
                    (counter % 2 === 0 ? "-dark " : "-light "),
                };
              } else if (value === "SOP Category") {
                return {
                  type: "text",
                  style: { paddingLeft: 10 },
                  text: `${SOP_Category}`,
                  nonEditable: true,
                  className:
                    "editable-cell" +
                    (counter % 2 === 0 ? "-dark " : "-light "),
                };
              }
              return {
                type: "dropdown",
                isOpen: false,
                values:
                  optionType[
                    DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                  ],
                className:
                  "editable-cell" + (counter % 2 === 0 ? "-dark " : "-light "),
              };
            } else if (value === "Split Id") {
              return {
                type: "text",
                text: `${splitId}`,
                className:
                  getCellClassName(SupplySkuNonEditableRows, value) +
                  (counter % 2 === 0 ? "-dark " : "-light "),
                nonEditable:
                  SupplySkuNonEditableRows.findIndex(
                    (value1) => value1 === value
                  ) !== -1,
              };
            } else if (dates.findIndex((value1) => value1 === value) !== -1) {
              return {
                type: "number",
                value: null,
                className:
                  getCellClassName(SupplySkuNonEditableRows, value) +
                  (counter % 2 === 0 ? "-dark " : "-light "),
                nonEditable:
                  SupplySkuNonEditableRows.findIndex(
                    (value1) => value1 === value
                  ) !== -1,
              };
            } else if (value === "Delete") {
              return { type: "text", className: "pale-red-bg ", text: "" };
            } else {
              return {
                type: "text",
                text: "",
                className:
                  getCellClassName(SupplySkuNonEditableRows, value) +
                  (counter % 2 === 0 ? "-dark " : "-light "),
                nonEditable:
                  SupplySkuNonEditableRows.findIndex(
                    (value1) => value1 === value
                  ) !== -1,
              };
            }
          }),
        ],
      });
      counter = counter + 1;
      dictionaryRow.push({
        rowId: `(${splitId + 1}, 1)-new`,
        cells: [
          ...CustomerMasterRows.map((value) => {
            if (DropDownRows.findIndex((value1) => value1 === value) !== -1) {
              if (value === "Warehouse Name") {
                return {
                  type: "text",
                  nonEditable: true,
                  text: "Disabled",
                  className:
                    "editable-cell" +
                    (counter % 2 === 0 ? "-dark " : "-light "),
                };
              }
              return {
                type: "dropdown",
                isOpen: false,
                values:
                  optionType[
                    DropDownMasterColumnsMapping[CustomerRowMapping[value]]
                  ],
                className:
                  "editable-cell" + (counter % 2 === 0 ? "-dark " : "-light "),
              };
            } else if (value === "Split Id") {
              return {
                type: "text",
                text: `${splitId + 1}`,
                className:
                  getCellClassName(SupplySkuNonEditableRows, value) +
                  (counter % 2 === 0 ? "-dark " : "-light "),
                nonEditable:
                  SupplySkuNonEditableRows.findIndex(
                    (value1) => value1 === value
                  ) !== -1,
              };
            } else if (dates.findIndex((value1) => value1 === value) !== -1) {
              return {
                type: "number",
                value: null,
                className:
                  getCellClassName(SupplySkuNonEditableRows, value) +
                  (counter % 2 === 0 ? "-dark " : "-light "),
                nonEditable:
                  SupplySkuNonEditableRows.findIndex(
                    (value1) => value1 === value
                  ) !== -1,
              };
            } else if (value === "Delete") {
              return {
                type: "text",
                text: "",
                className: "pale-red-bg ",
                nonEditable: true,
              };
            } else {
              return {
                type: "text",
                text: "",
                className:
                  getCellClassName(SupplySkuNonEditableRows, value) +
                  (counter % 2 === 0 ? "-dark " : "-light "),
                nonEditable:
                  SupplySkuNonEditableRows.findIndex(
                    (value1) => value1 === value
                  ) !== -1,
              };
            }
          }),
        ],
      });
      return dictionaryRow;
    },
    [
      CustomerRowMapping1,
      DropDownMasterColumnsMapping,
      DropDownRows,
      SupplySkuNonEditableRows,
      CustomerMasterRows1,
    ]
  );

  const handleColumnResize = (ci, width) => {
    if (Object.keys(dataGrouped).length === 0) return [];
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const handleDateAddition = (event) => {
    const currentDate1 = new Date(toAddDate);
    const maxDate = DatesOriginal.reduce((max, arr) => {
      var brr = new Date(arr);
      if (max < brr) {
        max = brr;
      }
      return max;
    }, new Date(DatesOriginal[0]));
    if (
      toAddDate === null ||
      toAddDate === "" ||
      currentDate1.getDay() !== 0 ||
      currentDate1 < new Date() ||
      currentDate1 > maxDate
    ) {
      var description = "";
      if (toAddDate === null || toAddDate === "") {
        description = "Selected Date should not be null";
      } else if (currentDate1.getDay() !== 0) {
        description = "Selected Date should be a Week Ending";
      } else if (currentDate1 < new Date()) {
        description = "Selected Date should be in future.";
      } else {
        description = "Dates should not be greater than maximum date";
      }
      const toastSettings = {
        description: description,
        status: "error",
        isClosable: true,
        duration: 2000,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    } else {
      setDates((prevData) => {
        const currentDate = new Date(toAddDate);
        const FormatDate = FormatDateToMonthYear1(currentDate);
        if (prevData.findIndex((value) => value === FormatDate) === -1) {
          prevData.push(FormatDate);
        }
        prevData.sort((a, b) => {
          return new Date(a).getTime() - new Date(b).getTime();
        });
        setColumns(
          getColumns(dataGrouped, prevData, deleteCheckBox, showPrevious)
        );
        setRows(
          getRows(
            dataGrouped,
            dataGroupedOriginal,
            optionType,
            prevData,
            deleteCheckBox,
            deleteCheckBoxColumns,
            showPrevious
          )
        );
        return prevData;
      });

      SetToAddDate(null);
    }
  };

  useEffect(() => {
    if (!account) {
      navigate("/");
    } else {
      if (ActiveEnv && Object.keys(dataGrouped).length === 0) {
        fetchData(retrieveURL, { method: "GET" }).then((data) => {
          setDataGrouped(data["ExistingTable"]);
          setDataGroupedOriginal(
            JSON.parse(JSON.stringify(data["ExistingTable"]))
          );
          setDropDownMaster(data["DropdownMasters"]);
          setDates([...Object.values(data["Dates"]["Dates"])]);
          setDatesOriginal([...Object.values(data["Dates"]["Dates"])]);
          var temp = getOptionType(data["DropdownMasters"]);
          setOptionType(temp);
          setColumns(
            getColumns(
              data["ExistingTable"],
              Object.values(data["Dates"]["Dates"]),
              deleteCheckBox,
              showPrevious
            )
          );
          setRows(
            getRows(
              data["ExistingTable"],
              data["ExistingTable"],
              temp,
              Object.values(data["Dates"]["Dates"]),
              deleteCheckBox,
              deleteCheckBoxColumns,
              showPrevious
            )
          );
          const arrayofkeys = Object.keys(data["ExistingTable"]);
          const maxIDinEffect =
            data["ExistingTable"][arrayofkeys[arrayofkeys.length - 1]][
              "SplitId"
            ];
          setMaxID(maxIDinEffect);
          setMaxIDInDB(maxIDinEffect);
        });
        setDataSaved(true);
      }
    }
  }, [
    account,
    navigate,
    ActiveEnv,
    setDataSaved,
    retrieveURL,
    dataGrouped,
    getRows,
    getColumns,
    getOptionType,
    deleteCheckBox,
    deleteCheckBoxColumns,
    fetchData,
    showPrevious,
  ]);

  const uploadData = () => {
    setIsButtonDisabled(true);
    let data = [];
    let blankSpace = {};
    Object.entries(changesTracker).forEach(([key1, value1]) => {
      const splitId = key1.split("-")[1];
      const dates_modified = value1["DatesModified"]
        ? value1["DatesModified"]
        : [];
      dates_modified.forEach((value2) => {
        let sumofwarehouse = 0;
        Object.entries(dataGrouped)
          .filter(([key3, value3]) => value3["SplitId"] === parseInt(splitId))
          .forEach(([key4, value4]) => {
            sumofwarehouse =
              sumofwarehouse + (isNaN(value4[value2]) ? 0 : value4[value2]);
          });
        if (sumofwarehouse !== 1) {
          blankSpace[`${splitId}`] = blankSpace[`${splitId}`]
            ? blankSpace[`${splitId}`].add(value2)
            : new Set([value2]);
        }
      });
    });
    if (Object.keys(blankSpace).length > 0) {
      let ErrorMsg = "";
      Object.entries(blankSpace).forEach(([key, value]) => {
        ErrorMsg =
          ErrorMsg +
          "For split id " +
          key +
          " on following dates- " +
          Array.from(value).reduce((p, v) => {
            return `${p}, ${v}`;
          }) +
          " the total proportion is not equal to 1.\n ";
      });
      const toastSettings = {
        description: ErrorMsg,
        status: "error",
        isClosable: true,
        duration: null,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    } else {
      Object.entries(changesTracker).forEach(([key, newValue]) => {
        const [Env, ID, newCustomer] = key.split("-");
        data.push({
          updatedDict: newValue,
          ID: ID,
          newCustomer: newCustomer,
          comment: comment,
        });
      });
      updateData(updateURL, { method: "PUT", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          // updateTotals(totals, pivotedData, setTotals, setPivotedData, changesTracker)
          setDataSaved(true);
          setMaxIDInDB(findMaxId(Object.entries(dataGrouped), "SplitId"));
          setDataGroupedOriginal(JSON.parse(JSON.stringify(dataGrouped)));
          setDatesOriginal([...Dates]);
          setChangesTracker({});
          setIsAlertBoxOpen(!isAlertBoxOpen);
          setDeleteConfirmation(null);
        });
    }
  };

  const handleDeleteCheckbox = () => {
    if (!deleteCheckBox === false) {
      setDeleteTracker((prevData) => {
        prevData["rowstodelete"] = [];
        return prevData;
      });
    }
    setDeleteCheckBox(!deleteCheckBox);
    setColumns(getColumns(dataGrouped, Dates, !deleteCheckBox, showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        Dates,
        !deleteCheckBox,
        deleteCheckBoxColumns,
        showPrevious
      )
    );
  };

  const handleShowPrevious = () => {
    setShowPrevious(!showPrevious);
    setColumns(getColumns(dataGrouped, Dates, deleteCheckBox, !showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        Dates,
        deleteCheckBox,
        deleteCheckBoxColumns,
        !showPrevious
      )
    );
  };

  const DeleteRowsandColumns = async () => {
    const deleteDates = deleteTracker["columnstodelete"];
    const dates123 = Dates;
    let changesTracker1 = {...changesTracker}
    deleteDates.forEach((deletevalue) => {
      const indexToRemove = dates123.indexOf(deletevalue);
      if (indexToRemove !== -1) {
        dates123.splice(indexToRemove, 1);
      }
      let changesTrackerToRemove=Object.entries(changesTracker1).map(([key,value])=>{
        if(value["changeType"] && value["changeType"].includes("DateProportionAdded")){
          if(value["DatesModified"].has(deletevalue)){
            Object.keys(value["warehouse_code_changed"]).forEach((value1)=>{
             delete value[value1][deletevalue]
            })
          }
          
        }
        return [key,value]
      })
      changesTracker1 = Object.fromEntries(changesTrackerToRemove)
    });

    var columnstodeleteindatabase = DatesOriginal.filter(
      (item) => !dates123.includes(item)
    );
    if (columnstodeleteindatabase.length > 0) {
      let data = {};
      data["columnstodeleteindatabase"] = columnstodeleteindatabase;
      data["deletecolumn"] = true;
      data["comment"] = comment;
      await deleteData(deleteURL, { method: "DELETE", body: data })
        .then((data) => {
          if (data.Error) return;
        })
        .then(() => {
          setDataSaved(true);
          setDatesOriginal([...dates123]);
          setDeleteTracker((prevData) => {
            prevData["columnstodelete"] = [];
            return prevData;
          });
          setDates(dates123);
          setChangesTracker(changesTracker1)
          
        })
        .catch((error) => {
          const toastSettings = {
            description: error.response.data.Error,
            status: "error",
            isClosable: true,
            duration: 2000,
          };
          if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastSettings);
          } else {
            toast(toastSettings);
          }
        });
    } else {
      deleteDates.forEach((deletevalue) => {
        let changesTrackerToRemove=Object.entries(changesTracker1).map(([key,value])=>{
          if(value["changeType"] && value["changeType"].includes("DateProportionAdded")){
            if(value["DatesModified"].has(deletevalue)){
              Object.keys(value["warehouse_code_changed"]).forEach((value1)=>{
               delete value[value1][deletevalue]
              })
            }
          }
          return [key,value]
        })
        changesTracker1 = Object.fromEntries(changesTrackerToRemove)
      });
      setChangesTracker(changesTracker1)
      setDates(dates123);
      const toastSettings = {
        description: "Data Deleted Successfully",
        status: "success",
        isClosable: true,
        duration: 2000,
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    }
    setColumns(getColumns(dataGrouped, dates123, deleteCheckBox, showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        dates123,
        deleteCheckBox,
        deleteCheckBoxColumns,
        showPrevious
      )
    );
    setIsAlertBoxOpen(!isAlertBoxOpen);
    setDeleteConfirmation(null);
  };

  const deleteSelectedRows = async () => {
    const rowstodelete = deleteTracker["rowstodelete"];
    const blankSpace = [];
    const sumofdates = {};
    var doneSplitId = {};
    let changesTracker1 = changesTracker
    rowstodelete.forEach((value) => {
      if (doneSplitId[dataGrouped[value]["SplitId"]] !== true) {
        var splitId = dataGrouped[value]["SplitId"];
        var warehouse_split_id = rowstodelete.filter(
          (value_s) => dataGrouped[value_s]["SplitId"] === splitId
        );
        var dataGroupedFiltered = Object.entries(dataGrouped).filter(
          ([key3, value3]) =>
            value3["SplitId"] === parseInt(splitId) &&
            !warehouse_split_id.includes(key3)
        );
        dataGroupedFiltered.forEach(([key1, value1]) => {
          Dates.forEach((date) => {
            sumofdates[`${splitId}-${date}`] = sumofdates[`${splitId}-${date}`]
              ? sumofdates[`${splitId}-${date}`]
              : 0;

            sumofdates[`${splitId}-${date}`] =
              sumofdates[`${splitId}-${date}`] +
              (isNaN(value1[date]) | (value1[date] === null)
                ? 0
                : parseFloat(value1[date]));
          });
        });
        Object.entries(sumofdates).forEach(([key2, value2]) => {
          if (!((value2 === 1) | (value2 === 0))) {
            const [splitId, date] = key2.split("-");
            blankSpace.push({ SplitId: splitId, Date: date });
          }
        });
        doneSplitId[dataGrouped[value]["SplitId"]] = true;
      }
    });
    if (blankSpace.length > 0) {
      let ErrorMsg = "";
      blankSpace.forEach((value) => {
        ErrorMsg =
          ErrorMsg +
          "For split id " +
          value["SplitId"] +
          " on following dates- " +
          value["Date"] +
          " the total proportion  for remaining warehouses is not equal to 1.\n ";
      });
      const toastSettings = {
        description: ErrorMsg,
        status: "error",
        isClosable: true,
        duration: null,
        containerStyle: {
          maxHeight: "300px",
        },
      };
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings);
      } else {
        toast(toastSettings);
      }
    } else {
      var rows_to_database = [];
      rowstodelete.forEach((value) => {
        if (dataGroupedOriginal[value]) {
          rows_to_database.push({
            splitId: dataGroupedOriginal[value]["SplitId"],
            warehouse_code: dataGrouped[value]["warehouse_code"],
          });
        }
      });
      if (rows_to_database.length > 0) {
        let data = {};
        data["rowstodeleteindatabase"] = rows_to_database;
        data["deleterow"] = true;
        data["comment"] = comment;
        await deleteData(deleteURL, { method: "DELETE", body: data })
          .then((data) => {
            if (data.Error) return;
          })
          .then(() => {
            let dataGrouped1 = dataGrouped;
            let dataGroupedOriginal1 = dataGroupedOriginal;
            rowstodelete.forEach((value147) => {
              delete dataGrouped1[value147];
              const split_type = parseInt(changesplitId) > maxIDInDB ? "new" : "old";
              var [rowId, isNew] = value147.split("-");
              var [changesplitId, changeId] = rowId.split(" ");
              changesplitId = parseInt(changesplitId.replace("(", "").replace(" ", ""));
              let changesTrackerModified = Object.entries(changesTracker1[`${ActiveEnv}-${changesplitId}-${split_type}`]).map(([key,value])=>{
                if(value["warehouse_code_changed"]){
                Object.keys(value["warehouse_code_changed"]).forEach((warehouse_delete)=>{
                  if(value[warehouse_delete]){
                    delete value[warehouse_delete]
                  }
                })
              }
              if(value["warehouse_code_added"]){
                Object.keys(value["warehouse_code_added"]).forEach((warehouse_delete)=>{
                  if(value[warehouse_delete]){
                    delete value[warehouse_delete]
                  }
                })
              }
                return [key,value]
              })
              changesTracker1[`${ActiveEnv}-${changesplitId}-${split_type}`]=Object.fromEntries(changesTrackerModified)
              delete dataGroupedOriginal1[value147];
            });
            setDataGrouped(dataGrouped1);
            setDataGroupedOriginal(dataGroupedOriginal1);
            setMaxID(findMaxId(Object.entries(dataGrouped), "SplitId"));
            setMaxIDInDB(findMaxId(Object.entries(dataGrouped), "SplitId"));
            setChangesTracker(changesTracker1)
          })
          .catch((error) => {
            const toastSettings = {
              description: error.response.data.Error,
              status: "error",
              isClosable: true,
              duration: 2000,
            };
            if (toastIdRef.current) {
              toast.update(toastIdRef.current, toastSettings);
            } else {
              toast(toastSettings);
            }
          });
      } else {
        
        rowstodelete.forEach((value147) => {
          
              var [rowId, isNew] = value147.split("-");
              var [changesplitId, changeId] = rowId.split(" ");
              changesplitId = parseInt(changesplitId.replace("(", "").replace(" ", ""));   
              const split_type = parseInt(changesplitId) > maxIDInDB ? "new" : "old";
              console.log(changesTracker1,value147,`${ActiveEnv}-${changesplitId}-${split_type}`)
              let changesTrackerModified = Object.entries(changesTracker1[`${ActiveEnv}-${changesplitId}-${split_type}`]).map(([key,value])=>{
              if(value["warehouse_code_changed"]){
              Object.keys(value["warehouse_code_changed"]).forEach((warehouse_delete)=>{
                if(value[warehouse_delete]){
                  delete value[warehouse_delete]
                }
              })
            }
            if(value["warehouse_code_added"]){
              Object.keys(value["warehouse_code_added"]).forEach((warehouse_delete)=>{
                if(value[warehouse_delete]){
                  delete value[warehouse_delete]
                }
              })
            }
              return [key,value]
            })
            changesTracker1[`${ActiveEnv}-${changesplitId}-${split_type}`]=Object.fromEntries(changesTrackerModified)
          delete dataGrouped[value147];
        });
       
        setDataGrouped(dataGrouped);
        setMaxID(findMaxId(Object.entries(dataGrouped), "SplitId"));
        const toastSettings = {
          description: "Data Deleted Successfully",
          status: "success",
          isClosable: true,
          duration: 2000,
        };
        if (toastIdRef.current) {
          toast.update(toastIdRef.current, toastSettings);
        } else {
          toast(toastSettings);
        }
      }

      setDeleteTracker((prevData) => {
        prevData["rowstodelete"] = [];
        return prevData;
      });
      setColumns(getColumns(dataGrouped, Dates, deleteCheckBox, showPrevious));
      setRows(
        getRows(
          dataGrouped,
          dataGroupedOriginal,
          optionType,
          Dates,
          deleteCheckBox,
          deleteCheckBoxColumns,
          showPrevious
        )
      );
    }
    setIsAlertBoxOpen(!isAlertBoxOpen);
    setDeleteConfirmation(null);
  };

  const handleDeleteCheckboxcolumns = () => {
    if (!deleteCheckBoxColumns === false) {
      setDeleteTracker((prevData) => {
        prevData["columnstodelete"] = [];
        return prevData;
      });
    }
    setDeleteCheckBoxColumns(!deleteCheckBoxColumns);
    setColumns(getColumns(dataGrouped, Dates, deleteCheckBox, showPrevious));
    setRows(
      getRows(
        dataGrouped,
        dataGroupedOriginal,
        optionType,
        Dates,
        deleteCheckBox,
        !deleteCheckBoxColumns,
        showPrevious
      )
    );
  };


  if (error) return <p>Follwing Error has occurred {error}</p>;
  if (loading || !ActiveEnv)
    return (
      <Container
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          className="Loading-Data"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          boxShadow="md"
          backgroundColor={"white"}
        >
          <CustomSpinner text="Loading Your Data.. Please Wait" />
        </Box>
      </Container>
    );

  // .filter(([filterKey,filterValue])=>(customer_name_filter==="All"|filterValue[CustomerRowMapping["Customer Name"]] ===customer_name_filter|filterValue[CustomerRowMapping["Customer Name"]] ==="")&&(filterValue[CustomerRowMapping["Channel"]] ===channel_filter|filterValue[CustomerRowMapping["Channel"]] ==="")&&(filterValue[CustomerRowMapping["SOP Category"]] ===sop_category_filter|filterValue[CustomerRowMapping["SOP Category"]] ===""))

  return (
    <Flex flex="1" flexDirection="column">
      <Container maxW="container.xxl" py={8}>
        <Stack spacing={8}>
          <Tabs variant="enclosed" bg="white" boxShadow="md">
            <TabList>
              <Tab>
                {
                  prettySheets.filter(
                    (value) => value[0] === parseInt(id_sheet)
                  )[0][1]
                }
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <HStack spacing={4}>
                  <SaveButton
                    isButtonDisabled={isButtonDisabled}
                    uploadData={() => {
                      setIsAlertBoxOpen(!isAlertBoxOpen);
                      setDeleteConfirmation("Save");
                    }}
                  />

                  <Button
                    style={{ marginTop: "16px", width: "130px" }}
                    fontSize="md"
                    fontWeight="bold"
                    onClick={onOpen}
                    colorScheme="blue"
                    leftIcon={<BsCardList />}
                  >
                    Change Logs
                  </Button>
                  <Tooltip
                    label={
                      !showPrevious
                        ? "Show Previous Split Values"
                        : "Hide Previous Split Values"
                    }
                    placement="top"
                  >
                    <Button
                      style={{ marginTop: "16px", width: "140px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={handleShowPrevious}
                      colorScheme="blue"
                      leftIcon={!showPrevious ? <BiShow /> : <BiHide />}
                    >
                      {!showPrevious ? "Show" : "Hide"} Previous
                    </Button>
                  </Tooltip>
                  <Input
                    style={{ marginTop: "16px", width: "250px" }}
                    type="Date"
                    value={toAddDate ? toAddDate : ""}
                    onChange={(e) => SetToAddDate(e.target.value)}
                  ></Input>

                  <Button
                    style={{ marginTop: "16px", width: "110px" }}
                    fontSize="md"
                    fontWeight="bold"
                    isDisabled={!toAddDate || toAddDate === ""}
                    onClick={handleDateAddition}
                    colorScheme="blue"
                    leftIcon={<IoAddCircleOutline />}
                  >
                    Add Date
                  </Button>

                  {!deleteCheckBox && (
                    <Button
                      style={{ marginTop: "16px", width: "130px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={handleDeleteCheckbox}
                      colorScheme="red"
                      leftIcon={<MdOutlineTableRows />}
                    >
                      Delete Rows
                    </Button>
                  )}

                  {deleteCheckBox && (
                    <Button
                      style={{ marginTop: "16px", width: "190px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={() => {
                        setIsAlertBoxOpen(!isAlertBoxOpen);
                        setDeleteConfirmation("Rows");
                      }}
                      colorScheme="red"
                      leftIcon={<MdOutlineTableRows />}
                    >
                      Delete Selected Rows
                    </Button>
                  )}

                  {deleteCheckBox && (
                    <Button
                      style={{ marginTop: "16px", width: "190px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={handleDeleteCheckbox}
                      colorScheme="yellow"
                      leftIcon={<MdOutlineCancel />}
                    >
                      Cancel Delete Rows
                    </Button>
                  )}

                  {!deleteCheckBoxColumns && (
                    <Button
                      style={{ marginTop: "16px", width: "130px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={handleDeleteCheckboxcolumns}
                      colorScheme="red"
                      leftIcon={<BsCalendar2X />}
                    >
                      Delete Dates
                    </Button>
                  )}

                  {deleteCheckBoxColumns && (
                    <Button
                      style={{ marginTop: "16px", width: "190px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={() => {
                        setIsAlertBoxOpen(!isAlertBoxOpen);
                        setDeleteConfirmation("Dates");
                      }}
                      colorScheme="red"
                      leftIcon={<BsCalendar2X />}
                    >
                      Delete Selected Dates
                    </Button>
                  )}

                  {deleteCheckBoxColumns && (
                    <Button
                      style={{ marginTop: "16px", width: "190px" }}
                      fontSize="md"
                      fontWeight="bold"
                      onClick={handleDeleteCheckboxcolumns}
                      colorScheme="yellow"
                      leftIcon={<MdOutlineCancel />}
                    >
                      Cancel Delete Dates
                    </Button>
                  )}

                  {prettySheets && (
                    <Box position="absolute" right="2%">
                      <RegionalLeaderTable
                        name={
                          prettySheets.filter(
                            (value) => value[0] === parseInt(id_sheet)
                          )[0][1]
                        }
                      />
                    </Box>
                  )}
                </HStack>
                <Box minHeight="10vh" paddingBottom={10}>
                  <Box
                    maxHeight="60vh"
                    minHeight="25vh"
                    overflow="auto"
                    textAlign="center"
                    id="printableArea"
                    className="react-grid"
                  >
                    {/* <ReactGrid rows={rows} columns={columns} onCellsChanged={handleChanges} stickyTopRows={1} stickyLeftColumns={1} disableVirtualScrolling={true} stickyRightColumns={1} onColumnResized={handleColumnResize} enableColumnSelection enableRangeSelection enableRowSelection></ReactGrid> */}
                    <div id="reactgrid-red">
                      <ReactGrid
                        rows={rows}
                        columns={columns}
                        stickyTopRows={deleteCheckBoxColumns ? 2 : 1}
                        stickyLeftColumns={deleteCheckBox ? 5 : 4}
                        disableVirtualScrolling={false}
                        onColumnResized={handleColumnResize}
                        onCellsChanged={handleChanges}
                        enableColumnSelection
                        enableRangeSelection
                        enableRowSelection
                      ></ReactGrid>
                    </div>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
        {isAlertBoxOpen && (
          <CustomAlertDialogBox
            isAlertBoxOpen={isAlertBoxOpen}
            setIsAlertBoxOpen={setIsAlertBoxOpen}
            onChangeCommentHandler={setComment}
            themeColor={deleteConfirmation === "Save" ? "green" : "red"}
            headerMsg={
              deleteConfirmation === "Save"
                ? "Please confirm to Save"
                : "Delete Selected " + deleteConfirmation + "?"
            }
            bodyMsg={"Are you sure? You can't undo this action afterwards."}
            onOKHandler={
              deleteConfirmation === "Save"
                ? uploadData
                : deleteConfirmation === "Rows"
                ? deleteSelectedRows
                : deleteConfirmation === "Dates"
                ? DeleteRowsandColumns
                : null
            }
          />
        )}
        {isOpen && (
          <ChangeLog
            PageName={pathname.split("-")[0]}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
          />
        )}
      </Container>
    </Flex>
  );
}
